import _ from "lodash";

export const CUSTOMER_PRIMARY_DOC_ID = 1;
export const CUSTOMER_SECONDARY_DOC_ID = 1;

export const COBORROWER_PRIMARY_DOC_ID = 43;
export const COBORROWER_SECONDARY_DOC_ID = 43;

export const SETTING = {
    CRYPTO_KEY: "U*oDwrIU!B#QX37%#24",
    RESEND_OTP_TIME_IN_SECOND: 30,
};
export const API_URL = process.env.REACT_APP_GETWAY_API;
export const API_KEY = process.env.REACT_APP_API_KEY;  
export const getFileExtensionFromURL = (url) => {
	try {
		return url && url.split(/#|\?/)[0].split('.').pop().trim();
	} catch (e) {
		return '';
	}
}

export const ADMIN_ROLE_ID = 1;

export const IS_USER_ACCESS_PAGE = (controller, action) => {
	let isUserHasRights = false, isEditAccess = false;
	// let loginData = localStorage.getItem("loginUserInfo");
	let loginData = JSON.parse(localStorage.getItem('user_information'));
	 let isSuperAdmin = loginData && loginData?.role_id?.includes(1);
	
	 if(isSuperAdmin){
		isUserHasRights=true;
		isEditAccess=true;
	 }else{
		const userAccessFeatures = JSON.parse(localStorage.getItem("userAccess"));
		if (userAccessFeatures && userAccessFeatures.length) {
			let isKeyExist = _.findKey(userAccessFeatures, function (v) {
				if (controller && v.controller && action && v.action) {
					return (
						v.controller.toLowerCase() === controller.toLowerCase() && 
						v.action.toLowerCase() === action.toLowerCase()
					);
				}
			});
			isUserHasRights = isKeyExist ? true : false;
			isEditAccess = isKeyExist ? ((Number(userAccessFeatures[isKeyExist].access_type) === 1) ? true : false) : false
		}
	}


	return {
		is_access_page: isUserHasRights,
		is_edit_access: isEditAccess
	};
};


export const CHECK_ASSIGN_USER = (assign_to) => {
	let loginData = JSON.parse(localStorage.getItem('user_information'));
	let isSuperAdmin = loginData && loginData.role_id.includes(1);
	if(isSuperAdmin) return false;
	return !(assign_to===loginData?.id);
};
export const TENURE_IN_MONTH = {
    "0": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "1": [{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "2": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "3": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "4": [{id:'12',label:'12',value:'12'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "5": [{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
    "6": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
    "7": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'},{id:'60',label:'60',value:'60'}],
	"8": [{id:'12',label:'12',value:'12'},{id:'18',label:'18',value:'18'},{id:'24',label:'24',value:'24'},{id:'30',label:'30',value:'30'},{id:'36',label:'36',value:'36'},{id:'48',label:'48',value:'48'}],
}

export const STATUS_CODES = {
    "APPLICATION": 1,
    "FINANCIER_APPROVAL": 2,
    "DISBURSEMENT": 3,
    "VEHICLE_TRANSFER": 4,
    "LOST": 5,
    "FINANCIER_COMPUTATION": 6,
}

export const SUB_STATUS_CODES = {
    "CUSTOMER_DETAIL_FILLED": 1,
    "APPLICATION_CREATED": 2,
    "SENT_TO_FINANCIER": 3,
    "SOFT_APPROVAL_RECEIVED": 4,
    "CREDIT_INVESTIGATION_SENT": 5,
    "CREDIT_ADVICE_RECEIVED": 6,
	"CONTRACT_SIGNED": 7,
	"COMPUTATION_GENERATED": 8,
	"COMPUTATION_RESET": 40,
    "COMPUTATION_REGENERATED": 41,
	"PAYMENT_VERIFICATION_DONE": 9,
	"PAID_TO_DEALER": 10,
	"LOAN_VALUE_RECEIVED": 11,
	"CAR_HANDOVER": 12,
	"ORCR_RECEIVED": 13,
	"ORCR_SUBMISSION": 14,
	"ORCR_VERIFIED": 15,
	"REJECT_INCORRECT_DATA": 16,
	"REJECT_JUNK_APPLICATION": 17,
	"CANCEL_BY_CARMUDI": 18,
	"CANCEL_BY_CUSTOMER": 19,
	"CANCEL_BY_DEALER": 20,
	"SOFT_APPROVAL_REJECTED": 21,
	"PAYMENT_CANCELLED": 22,
	"CREDIT_ADVICE_CANCELLED": 23,
	"ENVIRONMENT_CHECK": 24,
	"ORCR_REJECTED": 25,
	"OTHER": 26,
	"DEALER_INCENTIVE_RECEIVED": 27,
	"CREDIT_INVESTIGATION_RECEIVED": 28,
	"BASIC_DETAILS_FILLED": 29,
	"REJECT_APPLICATION_PROCESS_CHECK": 30,
	"COMPUTATION_VERIFIED": 31,
	"COMPUTATION_RESUBMIT": 32,
	"DEALER_INCENTIVE_PAID": 33,
	"LOAN_QUOTE_SELECTED": 37,
}

export const PAYMENT_PROOF_DOCS = {
    "MAIN_DEALER": 108,
    "MAIN_DEALER_PARENT": 107,
    "SPLIT_DEALER_1": 123,
    "SPLIT_DEALER_1_PARENT": 122,
    "SPLIT_DEALER_2": 125,
    "SPLIT_DEALER_2_PARENT": 124,
}

export const NAME_WITH_LATIN_CHARACTER_REGEX = /^[^0-9]*$/;

export const MAX_ADVANCE_LOAN_VALUE = 700000;
export const ADVANCE_DEALER_PAID = {
	ADP_PERCENTAGE: 80,
	ADP_SURCHARGE: {
		min: 0,
		max: 10,
		default: 2
	},
	TITLE_TRANSFER_CHARGE: {
		min: 0,
		max: 25000,
		default: 8000
	},
	ADP_PERCENTAGE_RANGE: {
		min: 50,
		max: 100,
		default: 80
	},
} 


export const calculatePercentageValue = (value = 0, percentage = 0) => {
	return (value*percentage)/100;
}

export const VEHICLE_TRANSFER_SUB_STATUS = [12, 13, 14, 15, 38, 39]

export const COUNTRY_CODE = "+63"
export const COUNTRY_CODE_REGEX = /^\+63\s*/

export const ALL_REQUIRED_DOC_IDS_PH = [2, 3, 4, 7, 8, 9, 10, 11, 12, 13];
export const FINANCIER_ID = {
	JACCS_FINANCE:	3
}

export const FINANCIER_DECIMAL_LIMIT = {
	JACCS_FINANCE: 15,
	DEFAULT: 8
}

export const FINANCIER_VALUE_LIMIT = {
	JACCS_FINANCE: 16,
	DEFAULT: 16
}

export const ROUNDOFF_TO_MAX_LIMIT = (value, limit) => {
	return (value.includes(".") && value.slice(0, (+limit+1))) || value.slice(0, limit);
}

export const TRANCHE_STATUS = {
    "paid": "Paid",
    "on_hold": "On Hold",
    "requested": "Requested",
    "generated": "Generated"
}

export const TITLE_TRANSFER_STATUS = {
    "paid": "Paid",
    "partial": "Partial",
    "requested": "Requested",
    "generated": "Generated",
}

export const computationRegeneratedActiveField = [
	"chattel_percentage",
	"chattel_fee",
	"dst_charges",
	"out_of_town_charges",
	"di_amount_pass_through",
	"ongoing_promo",
	"dealer_amount_from_financier_percent",
	"di_amount_from_financier_to_carmudi_percent",
	"di_amount_from_financier_to_carmudi_tax_percent",
	"dealer_incentive_percentage_gross",
	"carmudi_top_up_percentage",
	"dealer_incentive_tax_percent",
	"surcharge_for_adp",
	"title_transfer_charge",
	"vat_input",
	"exclusiveper",
	"inclusiveper",
]

// di_amount_from_financier_to_carmudi_percent
export const isRegeneratedFieldNeedToActive = (field_name) => {
	return !computationRegeneratedActiveField.includes(field_name);
}

export const NO_TOUCH_POLICY_FINANCIER = [1, 2, 4, 11];

export const CA_RECEIVED_OPTIONS = [
	{ value: "Yes", label: "Yes" },
	{ value: "No", label: "No" }
];


export const FIELDS_TO_DISABLE_IF_EXIST = {
	CREDIT_ADVICE_RECEIVED: ["custom_credit_advice_received_date", "pre_approval_received_date"]
};
export const isNeedsToDisable = (field_name, fieldFrom = [], value) => {
	return value && fieldFrom.includes(field_name);
}

export const TRUCK_IDS = [7, 8, 9];

export const CUSTOM_DATE_TYPE_OPTIONS = [
  { value: 'custom_contract_signed_date', label: 'Custom Contract Signed Date' }
]

export const getKeysDataIfExist = (object = {}, array = []) => {
	return array.reduce((result, key)=>{
		if(Array.isArray(key)) {
			if(object[key[0]]) result[key[1]] = object[key[0]];
		}
		else {
			if(object[key]) result[key] = object[key];
		}
		return result;
	},{}) || {};
}
export const ORCR_VERIFICATION_STATUS = {
    NOTVERIFIED: "Not Verified",
    VERIFIED: "Verified"
}

export const prepareTemplateDataIfExist = (object = {}, array = []) => {
    return (
        array.reduce((result, key) => {
            if (Array.isArray(key)) {
                result[`{{${key[1]}}}`] = (object[key[0]] || object[key[0]] === 0) ? object[key[0]] : "";
            } else {
                result[`{{${key}}}`] = (object[key] || object[key] === 0) ? object[key] : "";
            }
            return result;
        }, {}) || {}
    );
}

export const TT_PAYMENT_LABEL = {
	"title_transfer_payment1": "Title Transfer Payment 1",
	"title_transfer_payment2": "Title Transfer Payment 2"
}

export const TT_PAYMENT_SORT_ORDER = {
	"title_transfer_payment1": 1, 
	"title_transfer_payment2": 2
};

export const GOOGLE_AUTH_URL = process.env.GOOGLE_AUTH_URL || "https://sso.carmudi.com.ph/google?redirect=";
